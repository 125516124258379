var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-8 m-4 bg-white rounded vi-Room-label-list"},[_c('TableHead',{attrs:{"search-word":_vm.table_query.search_word,"searchPlaceholder":"搜索用户姓名、手机号"},on:{"update:searchWord":function($event){return _vm.$set(_vm.table_query, "search_word", $event)},"update:search-word":function($event){return _vm.$set(_vm.table_query, "search_word", $event)},"search":function($event){return _vm.tableGetData()},"refresh":function($event){return _vm.tableGetData()}},scopedSlots:_vm._u([{key:"left",fn:function(ref){
var size = ref.size;
return [_c('el-date-picker',{staticStyle:{"min-width":"350px"},attrs:{"type":"datetimerange","value-format":"yyyy-MM-dd HH:mm:ss","align":"center","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期","default-time":['00:00:00', '23:59:59']},on:{"change":function($event){return _vm.tableGetData()}},model:{value:(_vm.table_query.datetimes),callback:function ($$v) {_vm.$set(_vm.table_query, "datetimes", $$v)},expression:"table_query.datetimes"}})]}},{key:"before-right",fn:function(ref){
var size = ref.size;
return [_c('SelectUserRegion',{staticStyle:{"width":"60%"},attrs:{"filterable":"","clearable":""},on:{"change":function($event){return _vm.tableGetData()}},model:{value:(_vm.table_query.login_type_id),callback:function ($$v) {_vm.$set(_vm.table_query, "login_type_id", $$v)},expression:"table_query.login_type_id"}})]}}])}),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.table_state.loading),expression:"table_state.loading"}],staticClass:"mt-4",attrs:{"border":"","data":_vm.table_data}},[_c('el-table-column',{attrs:{"type":"selection","width":"55"}}),_c('el-table-column',{attrs:{"label":"序号","prop":"id","width":"80"}}),_c('el-table-column',{attrs:{"label":"头像","prop":"icon","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('img',{staticClass:"rounded w-14 h-14",attrs:{"src":row.avatar_url + _vm.oss56}})]}}])}),_c('el-table-column',{attrs:{"label":"用户来源","prop":"login_type_id","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.login_type_id == 1)?[_vm._v("安卓")]:_vm._e(),(row.login_type_id == 2)?[_vm._v("苹果")]:_vm._e(),(row.login_type_id == 3)?[_vm._v("h5")]:_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"昵称","prop":"nick_name","minWidth":"120"}}),_c('el-table-column',{attrs:{"label":"手机号","prop":"mobile","minWidth":"120"}}),_c('el-table-column',{attrs:{"label":"会员等级","prop":"vip_key","minWidth":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.vip_key == 0)?[_vm._v("白银会员")]:_vm._e(),(row.vip_key == 1)?[_vm._v("金卡会员")]:_vm._e(),(row.vip_key == 2)?[_vm._v("铂金会员")]:_vm._e(),(row.vip_key == 3)?[_vm._v("钻石会员")]:_vm._e(),(row.vip_key == 4)?[_vm._v("永久钻石会员")]:_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"余额","prop":"balance","minWidth":"120","formatter":function (row) { return row.balance && row.balance / 100; }}}),_c('el-table-column',{attrs:{"label":"总积分","prop":"total_integral","minWidth":"100"}}),_c('el-table-column',{attrs:{"label":"可用积分","prop":"integral","minWidth":"100"}}),_c('el-table-column',{attrs:{"label":"创建时间","prop":"create_time","width":"180","formatter":function (row) { return _vm.$filters.formatTime(row.create_time); }}})],1),_c('TablePagination',{attrs:{"query":_vm.table_query,"state":_vm.table_state},on:{"current-change":function($event){return _vm.tableGetData()},"size-change":function($event){;(_vm.table_query.index = 1), _vm.tableGetData()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }